import React, {useState} from 'react';
import fp from 'lodash/fp';

import {
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField as MUITextField,
} from '@material-ui/core';
import {useApi, components} from '@arborian/narrf';

import useHref from '_app/dataSource';

const {dt2} = components;

function AddScope({value, onChange}) {
    const [open, setOpen] = useState(false);
    const [scope, setScope] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setScope('');
    };
    const handleAdd = () => {
        onChange([...value, scope]);
        handleClose();
    };

    return (
        <>
            <Button onClick={handleOpen}>Add Scope</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                        Please enter the name of the new scope
                    </DialogContentText>
                    <MUITextField
                        label='Scope'
                        value={scope}
                        onChange={ev => setScope(ev.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAdd}
                        color='primary'
                        variant='contained'
                    >
                        Add Scope
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function ScopeList({value, onChange}) {
    const scopes = value || [];
    const handleDelete = scope => ev => {
        onChange(fp.filter(s => s !== scope, scopes));
    };
    return (
        <>
            {fp.map(
                s => (
                    <Chip
                        key={s}
                        label={s}
                        onDelete={onChange && handleDelete(s)}
                    />
                ),
                scopes,
            )}
            {onChange && <AddScope value={scopes} onChange={onChange} />}
        </>
    );
}

export default function AdminRoles({app}) {
    const api = useApi();
    const dsRoles = useHref({
        href: fp.get('relationships.roles.links.related', app),
        prefix: 'roles.',
    });

    const onRowAdd = async rowData => {
        await api.fetchJson(api.url_for('rest.roles'), {
            method: 'POST',
            json: {
                data: {
                    type: 'Role',
                    attributes: rowData.data.attributes,
                },
            },
        });
    };

    const onRowUpdate = async (newData, oldData) => {
        await api.fetchJson(newData.data.links.self, {
            method: 'PATCH',
            json: {
                data: {
                    type: 'Role',
                    attributes: fp.omit(['app_id'], newData.data.attributes),
                },
            },
        });
    };

    const onRowDelete = async oldData => {
        await api.fetchJson(oldData.data.links.self, {method: 'DELETE'});
    };

    if (!app) return null;
    return (
        <dt2.DataTable
            dataSource={dsRoles}
            options={{search: false}}
            editable={{
                onRowAdd,
                onRowUpdate,
                onRowDelete,
            }}
        >
            <dt2.Column title='Name' field='attributes.name' />
            <dt2.Column title='Description' field='attributes.description' />
            <dt2.Column
                title='Scopes'
                field='attributes.scopes'
                render={rowData => (
                    <ScopeList
                        value={fp.get('data.attributes.scopes', rowData)}
                    />
                )}
                EditComponent={ScopeList}
            />
            <dt2.Action
                free
                id='refresh'
                icon='refresh'
                tooltip='Refresh'
                onClick={ev => dsRoles.fetch()}
            />
        </dt2.DataTable>
    );
}
