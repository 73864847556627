import {useState, useCallback} from 'react';

export const useStorage = (storage, key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const svalue = storage.getItem(key);
            return svalue ? JSON.parse(svalue) : initialValue;
        } catch (error) {
            console.error('Error loading storage', error);
            return initialValue;
        }
    });

    const setValue = useCallback(
        value => {
            try {
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.error('Error saving storage', error);
            }
        },
        [storage, key, storedValue, setStoredValue],
    );
    return [storedValue, setValue];
};
