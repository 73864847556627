import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useApi, util} from '@arborian/narrf';

import {url_for} from '_app/components/Router';

import BasePage from './BasePage';

export default function AuthorizationCode() {
    const api = useApi();
    const query = util.useQuery();
    const history = useHistory();
    const [init, setInit] = useState(false);
    const [message, setMessage] = useState('Authorizing...');

    util.useAsyncEffect(async () => {
        if(init) return;
        setInit(true);
        const code = query.get('code');
        const intent = query.get('intent');
        const error = query.get('error');
        if(error) {
            setMessage(`Auth error: ${error}`);
            return;
        }

        if(intent === 'link') {
            let refreshed = await api.tryRefresh();
            if(refreshed) {
                await api.codeLink(code);
                history.push(url_for('profile'));
            } else {
                console.error('Failed to refresh');
                history.push(url_for('home'));
            }
        } else {
            let tok = await api.codeLogin(code);
            console.log('Got token response', tok);
            history.push(url_for('profile'));
        }
    }, [init]);

    return (
        <BasePage title="AAuth" subtitle="Callback">
            <div>{message}</div>
        </BasePage>
    );
}


