import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {useApi, components} from '@arborian/narrf';

const {useQueryFetchOptions, useDataActions, useReduxDataSource} = components;

const defaultFetchOptions = {
    page: {number: 0, size: 20},
};

const defaultOptions = {};

export default function useHref({
    href,
    prefix = null,
    options = defaultOptions,
    initialFetchOptions = defaultFetchOptions,
}) {
    const api = useApi();
    const history = useHistory();

    const [fetchOptions, setFetchOptions] = useQueryFetchOptions(
        history,
        prefix,
        initialFetchOptions,
    );

    const fetchItems = useCallback(
        async _fetchOptions => {
            await api.bootstrap();
            const result = await api.fetchDataTable(
                href,
                _fetchOptions,
                options,
            );
            return result;
        },
        [api, href, options],
    );

    const ds = useReduxDataSource({data: fetchItems, fetchOptions});
    const actions = useDataActions(fetchOptions, setFetchOptions);
    return {...ds, ...actions};
}
