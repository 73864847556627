import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Button,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { Switch, TextField } from 'formik-material-ui';
import {useApi, ducks} from '@arborian/narrf';


export default function ProviderConfigDialog({provider, open, onClose}) {
    const api = useApi();
    const dispatch = useDispatch();
    const plugin = useSelector(
        ducks.jsonapi.selectObject(['ProviderPlugin', provider.attributes.plugin_id])
    );
    const config = useSelector(ducks.jsonapi.selectObject(['ProviderConfig', provider.id]));
    
    useEffect(() => {
        if(provider && !config) {
            api.fetchJson(provider.links.config);
        }
    }, [api, provider, config]);

    if(!fp.get('attributes.allow_configure', plugin)) return null;

    const handleSubmit = async (data, formikProps) => {
        await api.fetchJson(config.links.self, {
            method: 'PATCH',
            json: {data: {
                type: 'ProviderConfig',
                attributes: data
            }}
        });
        onClose();
    };
    
    const handleDelete = async () => {
        await api.fetch(provider.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(provider));
        onClose();
    }

    const config_settings = fp.get('attributes.config', config);
    const allow_signup = fp.get('attributes.allow_signup', provider);
    const configurable_fields = fp.get('attributes.configurable_fields', plugin);
    const initialValues = {config: config_settings, allow_signup};

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Configure {fp.get('attributes.name', plugin)}</DialogTitle>
            <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>{formikProps => (
                <Form onSubmit={formikProps.handleSubmit}>
                    <DialogContent>
                        Allow signup? <Field name="allow_signup" label="Allow Signup" type="checkbox" component={Switch}/> 
                        {fp.map(c => (
                            <Field
                                key={c} name={`config.${c}`} label={c}
                                component={TextField} fullWidth InputLabelProps={{shrink: true}}
                            />
                        ), configurable_fields)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">Cancel</Button>
                        <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
                        <Button type="submit" variant="contained" color="primary">Save</Button>
                    </DialogActions>
                </Form>
            )}</Formik>
        </Dialog>
    );
}
