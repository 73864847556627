import React from 'react';
import {Provider} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import {createBrowserHistory} from 'history';
import {reducer, ApiProvider} from '@arborian/narrf';
import Router from '_app/components/Router';
import {ClientIdProvider} from '_app/components/ClientIdProvider';

const store = configureStore({reducer});
const history = createBrowserHistory();
console.log('GLobal history', history);

export default function App() {
    return (
        <Provider store={store}>
            <ApiProvider
                ui_home='/'
                history={history}
                api_root={process.env.REACT_APP_API_ROOT}
                client_id={process.env.REACT_APP_APP_KEY}
            >
                <ClientIdProvider>
                    <Router />
                </ClientIdProvider>
            </ApiProvider>
        </Provider>
    );
}
