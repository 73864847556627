import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {useApi, ducks} from '@arborian/narrf';

import BasePage from './BasePage';
import AdminProviders from '_app/components/AdminProviders';
import AdminRoles from '_app/components/AdminRoles';
import AdminSubs from '_app/components/AdminSubs';

import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';

function Section({title, children}) {
    return (
        <Accordion>
            <AccordionSummary>{title}</AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
}

export default function AccountPage() {
    const api = useApi();
    const app = useSelector(ducks.jsonapi.selectObject(['App', api.client_id]));

    useEffect(() => {
        api.fetchJsonApi(api.url_for('rest.plugins'));
        api.fetchJsonApi(api.url_for('rest.app'), {
            include: ['providers', 'providers.plugin'],
        });
    }, [api]);

    return (
        <BasePage title='AAuth' subtitle='Account Settings'>
            <Section title='Providers'>
                <AdminProviders app={app} />
            </Section>
            <Section title='Roles'>
                <AdminRoles app={app} />
            </Section>
            <Section title='Subs'>
                <AdminSubs app={app} />
            </Section>
        </BasePage>
    );
}
