import React, {useEffect} from 'react';
import fp from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';
import platform from 'platform';

import {ducks, useApi, lib} from '@arborian/narrf';

import {
    Avatar,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardHeader,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    makeStyles,
} from '@material-ui/core';
import * as I from '@material-ui/icons';
import {selectProviders, selectUserIdentities} from '_app/selectors';

import BasePage from './BasePage';

const useStyles = makeStyles(theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },

    cardArea: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
    },
    card: {
        margin: theme.spacing(1),
    },
}));

function IdentityAvatar({picture}) {
    if (picture) {
        return <Avatar src={picture} />;
    } else {
        return <Avatar icon={<I.Face />} />;
    }
}

function WebAuthNProviderCard({provider}) {
    const api = useApi();
    const classes = useStyles();
    const plugin = useSelector(
        ducks.jsonapi.selectObject([
            'ProviderPlugin',
            provider.attributes.plugin_id,
        ]),
    );
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    if (!provider || !plugin || !fp.get('attributes.allow_link', plugin))
        return null;
    const title = fp.get('attributes.name', plugin);
    const image = fp.get('attributes.image_url', plugin);

    const handleLink = async ev => {
        let credentialName = `${platform.name} on ${platform.os.family}`;
        console.log({platform});
        const reg_url = api.url_for('webauthn.create_registration', {
            provider_id: provider.id,
        });
        const credentialData = await lib.webauthn.register(
            api,
            provider,
            userinfo,
        );
        credentialName = prompt('Name of this device?', credentialName);
        const identity = await api.fetchJson(reg_url, {
            method: 'POST',
            json: {
                data: {
                    type: 'WebAuthNRegistration',
                    attributes: {
                        name: credentialName,
                        credentialData,
                    },
                },
            },
        });

        console.log({identity});
        const clientId = api.client_id;
        const credentialId = fp.get(
            'data.attributes.claims.credential_id',
            identity,
        );
        localStorage.setItem(`${clientId}.webauthn`, credentialId);
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                title={title}
                avatar={<Avatar src={image} icon={<I.Fingerprint />} />}
                action={
                    <IconButton onClick={handleLink}>
                        <I.Add />
                    </IconButton>
                }
            />
        </Card>
    );
}

function ProviderCard({provider}) {
    const api = useApi();
    const classes = useStyles();
    const plugin = useSelector(
        ducks.jsonapi.selectObject([
            'ProviderPlugin',
            provider.attributes.plugin_id,
        ]),
    );

    if (!provider || !plugin || !fp.get('attributes.allow_link', plugin))
        return null;
    const title = fp.get('attributes.name', plugin);
    const image = fp.get('attributes.image_url', plugin);
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        provider_id: fp.get('id', provider),
        redirect_uri,
        intent: 'link',
    });

    if (plugin.id === 'webauthn')
        return <WebAuthNProviderCard provider={provider} />;

    return (
        <Card className={classes.card}>
            <CardHeader
                title={title}
                avatar={<Avatar src={image} icon={<I.Face />} />}
                action={
                    <IconButton href={authorizeLink}>
                        <I.Add />
                    </IconButton>
                }
            />
        </Card>
    );
}

function IdentityCard({value, disableDelete}) {
    const api = useApi();
    const dispatch = useDispatch();
    const classes = useStyles();
    const onClickDelete = async ev => {
        await api.fetch(value.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(value));
    };
    if (!value) return null;
    const claims = fp.get('attributes.claims', value);

    const picture = fp.get('picture', claims);
    const name = fp.getOr('--', 'name', claims);
    const plugin = fp.get('attributes.plugin_id', value);
    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={<IdentityAvatar picture={picture} />}
                title={name}
                subheader={plugin}
                action={
                    <IconButton
                        onClick={onClickDelete}
                        disabled={disableDelete}
                    >
                        <I.Delete />
                    </IconButton>
                }
            />
        </Card>
    );
}

function PropertiesTable({value, ...props}) {
    return (
        <Table {...props}>
            <TableHead>
                <TableRow>
                    <TableCell>Claim</TableCell>
                    <TableCell>Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fp.flow([
                    fp.toPairs,
                    fp.map(([key, value]) => (
                        <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{JSON.stringify(value)}</TableCell>
                        </TableRow>
                    )),
                ])(value)}
            </TableBody>
        </Table>
    );
}

function Userinfo() {
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    return (
        <Accordion>
            <AccordionSummary>Userinfo Data</AccordionSummary>
            <AccordionDetails>
                <PropertiesTable size='small' value={userinfo} />
            </AccordionDetails>
        </Accordion>
    );
}

function Identities() {
    const classes = useStyles();
    const idents = useSelector(selectUserIdentities);
    const providers = useSelector(selectProviders);
    const disableDelete = idents && idents.length === 1;
    return (
        <Accordion defaultExpanded>
            <AccordionSummary>User Identites</AccordionSummary>
            <AccordionDetails className={classes.flexColumn}>
                <p>Existing Identities</p>
                <div className={classes.cardArea}>
                    {fp.map(
                        ident => (
                            <IdentityCard
                                key={ident.id}
                                value={ident}
                                disableDelete={disableDelete}
                            />
                        ),
                        idents,
                    )}
                </div>
                <p>Link a new identity</p>
                <div className={classes.cardArea}>
                    {fp.map(
                        provider => (
                            <ProviderCard
                                key={provider.id}
                                provider={provider}
                            />
                        ),
                        providers,
                    )}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default function ProfilePage() {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    useEffect(() => {
        if (userinfo && userinfo.sub_profile) {
            api.fetchJsonApi(api.url_for('rest.providers'), {
                filter: {app_id: api.client_id},
                include: ['plugin'],
            });
            api.fetchJsonApi(userinfo.sub_profile, {
                include: ['identities'],
            });
        }
    }, [api, userinfo]);

    return (
        <BasePage title='AAuth' subtitle='Profile'>
            <Userinfo />
            <Identities />
        </BasePage>
    );
}
