import React from 'react';
import {combineReducers} from 'redux';
import styles from './styles.module.css';

import ducks from './ducks';

export * as util from './util';
export * as ducks from './ducks';
export * as components from './components';
export * as lib from './lib';
export {useDialogContext, useDialog} from './components';

export {useApi, ApiRoute, default as ApiProvider} from './components/Api';
export {useAsyncError, default as ErrorBoundary} from './components/Error';

export const reducers = {narrf: ducks};

export const reducer = combineReducers(reducers);

export const ExampleComponent = ({text}) => {
    return <div className={styles.test}>Example Component: {text}</div>;
};
