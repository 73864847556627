import React, {useState} from 'react';

import {TextField, Button} from '@material-ui/core';

import BasePage from './BasePage';
import {useClientId} from '_app/components/ClientIdProvider';

export default function HomePage() {
    const [clientId, setClientId] = useClientId();
    const [value, setValue] = useState(clientId);

    const handleSubmit = ev => {
        ev.preventDefault();
        setClientId(value);
    };

    return (
        <BasePage title='AAuth' subtitle='Home'>
            <h1>Arborian Authorization Console</h1>
            <form onSubmit={handleSubmit}>
                <TextField
                    label='Client Key'
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
                <Button onClick={() => setValue(clientId)}>Reset</Button>
                <Button type='submit' variant='contained'>
                    Change client key
                </Button>
            </form>
        </BasePage>
    );
}
