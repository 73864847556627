import React from 'react';
import * as R from 'react-router-dom';
import {
    AppBar as MUIAppBar, IconButton, Toolbar, Link,
    makeStyles,
} from '@material-ui/core';

import {url_for} from '_app/components/Router';
import * as I from '@material-ui/icons';

import AuthInfo from '_app/components/AuthInfo';

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        margin: 0,
    },
    subtitle: {
    },
}));

export default function AppBar({onToggleDrawer, title, subtitle}) {
    const classes = useStyles();

    return (
        <MUIAppBar position="static">
            <Toolbar>
                <IconButton onClick={onToggleDrawer} color="inherit">
                    <I.Menu/>
                </IconButton>
                <h6 className={classes.title}>
                    <Link color="inherit" component={R.Link} to={url_for('home')}>
                        {title}
                    </Link> {subtitle && <span className={classes.subtitle}>| {subtitle}</span>}
                </h6>
                <AuthInfo />
            </Toolbar>
        </MUIAppBar>        
    )
}
    
