import React, {useEffect, useState, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';

import {
    IconButton,
    Avatar,
    Card,
    CardHeader,
    makeStyles,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {useApi, lib} from '@arborian/narrf';
import {url_for} from '_app/components/Router';
import BasePage from './BasePage';

const useStyles = makeStyles(theme => ({
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },

    cardArea: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
    card: {
        margin: theme.spacing(1),
    },
}));

function ProviderCard({value}) {
    const api = useApi();
    const classes = useStyles();

    if (!value) return null;
    console.log('ProviderCard', value);
    const name = fp.get('attributes.label', value);
    const image = fp.get('attributes.image_url', value);
    const title = `Login with ${name}`;
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        redirect_uri,
        provider_id: value.id,
        intent: 'login',
    });

    return (
        <Card className={classes.card}>
            <CardHeader
                title={title}
                avatar={<Avatar src={image} icon={<I.Face />} />}
                action={
                    <IconButton href={authorizeLink}>
                        <I.Launch />
                    </IconButton>
                }
            />
        </Card>
    );
}

function WebAuthNCard({value}) {
    const api = useApi();
    const classes = useStyles();
    const history = useHistory();
    const credentialId = localStorage.getItem(`${api.client_id}.webauthn`);
    const handleAuthenticate = async ev => {
        const tok = await lib.webauthn.authenticate(api, credentialId);
        console.log('Got token response', tok);
        history.push(url_for('profile'));
    };
    if (!credentialId) return null;
    return (
        <Card className={classes.card}>
            <CardHeader
                title='WebAuthN'
                avatar={<Avatar icon={<I.Fingerprint />} />}
                action={
                    <IconButton onClick={handleAuthenticate}>
                        <I.Launch />
                    </IconButton>
                }
            />
        </Card>
    );
}

export default function LoginPage() {
    const api = useApi();
    const classes = useStyles();
    const [providers, setProviders] = useState();

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({
            redirect_uri,
            intent: 'login',
        });
        try {
            let rv = await fetch(authorizeLink);
            if (rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(([id, attributes]) => ({id, attributes})),
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch (e) {
            console.log('Got error, trying direct redirect');
            window.location = authorizeLink;
        }
    }, [api, setProviders]);

    useEffect(() => {
        if (!providers) {
            fetchProviders();
        }
    }, [providers, fetchProviders]);

    return (
        <BasePage title='AAuth' subtitle='Login'>
            <div className={classes.cardArea}>
                <WebAuthNCard />
                {providers &&
                    fp.map(
                        p => <ProviderCard key={p.id} value={p} />,
                        providers,
                    )}
            </div>
        </BasePage>
    );
}
