import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';
import {createSelector} from '@reduxjs/toolkit';

const selectAsList = type => createSelector(
    ducks.jsonapi.selectObject(type),
    fp.values
);

export const selectPlugins = selectAsList('ProviderPlugin');
export const selectProviders = selectAsList('Provider');
export const selectRoles = selectAsList('Role');
export const selectIdentities = selectAsList('Identity');

export const selectConfigurablePlugins = createSelector(
    selectPlugins,
    fp.filter(fp.get('attributes.allow_configure'))
);

export const selectUserIdentities = createSelector(
    ducks.auth.selectUserinfo,
    selectIdentities,
    (userinfo, idents) => (fp.filter(
        id => fp.includes(fp.get('sub', userinfo), id.attributes.sub_ids)
    )(idents))
)

