import React from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import * as R from 'react-router-dom';

import {
    Drawer, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {url_for} from '_app/components/Router';
import {useApi, ducks} from '@arborian/narrf';


function AuthorizedEntries() {
    return (<>
        <ListItem button component={R.Link} to={url_for('profile')}>
            <ListItemIcon><I.Person /></ListItemIcon>
            <ListItemText primary="User Profile"/>
        </ListItem>
    </>);
}

function AdminEntries() {
    return (<>
        <ListItem button component={R.Link} to={url_for('account')}>
            <ListItemIcon><I.SupervisorAccount /></ListItemIcon>
            <ListItemText primary="Account Settings"/>
        </ListItem>
    </>);
}

export default function NavDrawer({open, onClose}) {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);

    const isAdmin = fp.flow([
        fp.get('scopes'),
        fp.indexOf('__admin__')
    ])(userinfo) !== -1;

    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                <ListItem button component={R.Link} to={url_for('home')}>
                    <ListItemIcon><I.Home /></ListItemIcon>
                    <ListItemText primary="Home"/>
                </ListItem>
                {api.isAuthorized() && <AuthorizedEntries />}
                {isAdmin && <AdminEntries />}
            </List>
        </Drawer>
    )
}
