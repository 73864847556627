import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Avatar, Button, Menu, MenuItem, ListItemText} from '@material-ui/core';
import * as I from '@material-ui/icons';
import {useApi, ducks} from '@arborian/narrf';
import {selectConfigurablePlugins} from '_app/selectors';

export default function AddProviderButton() {
    const api = useApi();
    const app = useSelector(ducks.jsonapi.selectObject('App', api.client_id));
    const plugins = useSelector(selectConfigurablePlugins);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = ev => {
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const addProvider = async (ev, p) => {
        ev.preventDefault();
        await api.fetchJson(api.url_for('rest.providers'), {
            method: 'POST',
            json: {
                data: {
                    type: 'Provider',
                    attributes: {
                        app_id: app.id,
                        plugin_id: p.id,
                    },
                },
            },
        });
        api.fetchJsonApi(api.url_for('rest.app'), {
            include: ['providers', 'providers.plugin'],
        });
        handleClose();
    };

    return (
        <>
            <Button onClick={handleClick} variant='contained'>
                <I.Add /> Add Provider
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {fp.map(
                    p => (
                        <MenuItem key={p.id} onClick={ev => addProvider(ev, p)}>
                            <Avatar
                                src={fp.get('attributes.image_url', p)}
                                icon={<I.Face />}
                            />
                            <ListItemText>
                                {fp.get('attributes.name', p)}
                            </ListItemText>
                        </MenuItem>
                    ),
                    plugins,
                )}
            </Menu>
        </>
    );
}
