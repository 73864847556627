import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Avatar, IconButton,
    Card, CardHeader,
    makeStyles
} from '@material-ui/core';
import * as I from '@material-ui/icons';
import {ducks} from '@arborian/narrf';
import {selectProviders} from '_app/selectors';

import AddProviderButton from '_app/components/AddProviderButton';
import ProviderConfigDialog from '_app/components/ProviderConfigDialog';

const useStyles = makeStyles(theme => ({
    cardArea: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    card: {
        margin: theme.spacing(1),
    },
}));

function ProviderCard({provider}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const plugin = useSelector(
        ducks.jsonapi.selectObject(['ProviderPlugin', provider.attributes.plugin_id])
    );
    if(!provider || !fp.get('attributes.allow_configure', plugin)) return null;

    const id = fp.get('id', provider);
    const title = fp.get('attributes.name', plugin);
    const image = fp.get('attributes.image_url', plugin);

    return (
        <>
            <ProviderConfigDialog provider={provider} open={open} onClose={() => setOpen(false)}/>
            <Card className={classes.card}>
                <CardHeader title={title} subheader={id}
                    avatar={<Avatar src={image} icon={<I.Face/>}/>}
                    action={<IconButton onClick={() => setOpen(true)}><I.Edit/></IconButton>}
                />
            </Card>
        </>
    )
}

export default function AdminProviders({app}) {
    const classes = useStyles();
    const providers = useSelector(selectProviders);
    console.log('Got providers', providers);

    return (
        <div className={classes.cardArea}>
            {fp.map(p => <ProviderCard key={p.id} provider={p}/>, providers)}
            <AddProviderButton />
        </div>
    )

}
