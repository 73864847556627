import React from 'react';
import * as R from 'react-router-dom';

import {reverse} from 'named-urls';

import {ApiRoute} from '@arborian/narrf';

import HomePage from '_app/pages/HomePage';
import ProfilePage from '_app/pages/ProfilePage';
import AccountPage from '_app/pages/AccountPage';

import RegisterPage from '_app/pages/RegisterPage';
import LoginPage from '_app/pages/LoginPage';
import CallbackPage from '_app/pages/CallbackPage';
import AuthorizedPage from '_app/pages/AuthorizedPage';

export const routes = {
    home: {path: '/'},
    profile: {path: '/profile'},
    settings: {path: '/settings'},
    account: {path: '/account'},
    login: {path: '/login'},
    register: {path: '/register'},
    authorized: {path: '/authorized'},
    callback: {path: '/callback'},
};

export const url_for = (route, params) => {
    try {
        let {path} = routes[route];
        return reverse(path, params)
    } catch(e) {
        console.error('Error looking up route', route, params)
        throw(e);
    }
}

export default function Router() {

    return (
        <R.BrowserRouter>
            <R.Switch>
                <ApiRoute exact path={routes.home.path} component={HomePage}/>
                <ApiRoute exact authorized path={routes.profile.path} component={ProfilePage}/>
                <ApiRoute exact authorized path={routes.account.path} component={AccountPage}/>




                <ApiRoute exact path={routes.register.path} component={RegisterPage}/>
                <ApiRoute exact path={routes.login.path} component={LoginPage}/>
                <ApiRoute exact path={routes.callback.path} component={CallbackPage}/>
                <ApiRoute exact authorized path={routes.authorized.path} component={AuthorizedPage}/>
            </R.Switch>
        </R.BrowserRouter>
    )
}
