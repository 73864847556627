import React, {useState, useEffect} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Avatar,
    Button,
    Tooltip,
    Chip,
    MenuItem,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField as MUITextField,
} from '@material-ui/core';
import * as I from '@material-ui/icons';
import {useApi, ducks, components} from '@arborian/narrf';
import useHref from '_app/dataSource';

const {dt2} = components;

const selectRoles = createSelector(
    ducks.jsonapi.selectObject('Role'),
    fp.values,
);

function AddRole({value, onChange}) {
    const roles = useSelector(selectRoles);
    const [open, setOpen] = useState(false);
    const [role, setRole] = useState('');
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRole('');
    };
    const handleAdd = () => {
        onChange([...value, role]);
        handleClose();
    };
    console.log('roles is', roles);

    return (
        <>
            <Button onClick={handleOpen}>Add Role</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>Please choose a role</DialogContentText>
                    <MUITextField
                        select
                        label='Role'
                        value={role}
                        onChange={ev => setRole(ev.target.value)}
                        fullWidth
                    >
                        {fp.map(
                            r => (
                                <MenuItem
                                    key={r.id}
                                    value={fp.get('attributes.name', r)}
                                >
                                    {fp.get('attributes.description', r)}
                                </MenuItem>
                            ),
                            roles,
                        )}
                    </MUITextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleAdd}
                        color='primary'
                        variant='contained'
                    >
                        Add Role
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function RoleList({value, onChange}) {
    const handleDelete = role => ev => {
        onChange(fp.filter(r => r !== role, value));
    };
    return (
        <>
            {fp.map(
                r => (
                    <Chip
                        key={r}
                        label={r}
                        onDelete={onChange && handleDelete(r)}
                    />
                ),
                value,
            )}
            {onChange && <AddRole value={value} onChange={onChange} />}
        </>
    );
}

function IdentList({sub}) {
    const sel = ducks.jsonapi.selectRelated(sub);
    const rels = useSelector(sel);
    return (
        <>
            {fp.map(
                ident => (
                    <IdentChip key={ident.id} value={ident} />
                ),
                rels.identities,
            )}
        </>
    );
}

function IdentChip({value, onChange}) {
    const sel = ducks.jsonapi.selectRelated(value);
    const rels = useSelector(sel);
    const image = fp.get('plugin.attributes.image_url', rels);
    const claims = fp.get('attributes.claims', value);
    const label = fp.get('email', claims) || fp.get('username', claims);
    const title = JSON.stringify(claims, null, 2);

    return (
        <Tooltip title={title}>
            <Chip
                avatar={<Avatar src={image} icon={<I.Face />} />}
                label={label}
            />
        </Tooltip>
    );
}

export default function AdminSubs({app}) {
    const api = useApi();
    const dsSubs = useHref({
        href: fp.get('relationships.subs.links.related', app),
        prefix: 'subs.',
        options: {
            include: ['identities', 'identities.provider', 'identities.plugin'],
        },
    });
    const roles_url = fp.get('relationships.roles.links.related', app);

    useEffect(() => {
        if (roles_url) {
            api.fetchAllJsonApi(roles_url);
        }
    }, [api, roles_url]);

    const onRowAdd = async rowData => {
        console.log('add rowData', rowData);
        await api.fetchJson(api.url_for('rest.subs'), {
            method: 'POST',
            json: {
                data: {
                    type: 'Sub',
                    attributes: rowData.data.attributes,
                },
            },
        });
    };

    const onRowUpdate = async (newData, oldData) => {
        console.log('update rowData', newData);
        await api.fetchJson(newData.data.links.self, {
            method: 'PATCH',
            json: {
                data: {
                    type: 'Sub',
                    attributes: fp.omit(['app_id'], newData.data.attributes),
                },
            },
        });
    };

    const onRowDelete = async oldData => {
        console.log('delete rowData', oldData);
        await api.fetchJson(oldData.data.links.self, {method: 'DELETE'});
    };

    if (!app) return null;
    return (
        <dt2.DataTable
            dataSource={dsSubs}
            options={{search: true}}
            editable={{
                onRowAdd,
                onRowUpdate,
                onRowDelete,
            }}
        >
            <dt2.Column
                id='name'
                title='Name'
                field='attributes.userinfo.name'
            />
            <dt2.Column
                id='email'
                title='Email'
                field='attributes.userinfo.email'
            />
            <dt2.Column
                id='roles'
                title='Roles'
                field='attributes.roles'
                render={rowData => (
                    <RoleList
                        value={fp.get('data.attributes.roles', rowData)}
                    />
                )}
                EditComponent={RoleList}
            />
            <dt2.Column
                id='identities'
                title='Identities'
                editable={false}
                render={rowData => <IdentList sub={rowData.data} />}
            />

            <dt2.Action
                free
                id='refresh'
                icon='refresh'
                tooltip='Refresh'
                onClick={ev => dsSubs.fetch()}
            />
        </dt2.DataTable>
    );
}
