import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {jsonapi} from '../../ducks';

const EMPTY = {};

export function useQueryFetchOptions(history, prefix = '', defaults = EMPTY) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [fetchOptions, setFetchOptions] = useState(
        locationFetchOptions(params, defaults, prefix),
    );

    // Set fetch options when prefix is defined & search changes
    useEffect(() => {
        if (!prefix) return;
        const params = new URLSearchParams(search);
        const newFetchOptions = locationFetchOptions(params, defaults, prefix);
        if (!fp.isEqual(newFetchOptions, fetchOptions)) {
            setFetchOptions(newFetchOptions);
        }
    }, [defaults, prefix, search, setFetchOptions]);

    // Set search when prefix is defined. This will trigger the effect
    //    above to set the state.
    const setSearchOptions = useCallback(
        o => {
            const newParams = updateLocationFetchOptions(o, prefix);
            history.push('?' + newParams.toString());
        },
        [history, prefix],
    );
    if (!prefix) {
        return [fetchOptions, setFetchOptions];
    } else {
        return [fetchOptions, setSearchOptions];
    }
}

export function useDataActions(fetchOptions, setFetchOptions) {
    const setSearch = useCallback(
        search => {
            const newFetchOptions = fp.pipe([
                fp.set('page.number', 0),
                fp.set('search', search),
            ])(fetchOptions);
            setFetchOptions(newFetchOptions);
        },
        [fetchOptions, setFetchOptions],
    );

    const setPage = useCallback(
        page => {
            setFetchOptions(fp.set('page.number', page, fetchOptions));
        },
        [fetchOptions, setFetchOptions],
    );

    const setPageSize = useCallback(
        pageSize => {
            setFetchOptions(fp.set('page.size', pageSize, fetchOptions));
        },
        [fetchOptions, setFetchOptions],
    );

    const setSort = useCallback(
        field => {
            const sort = fetchOptions.sort;
            // Go to page 1 when changing sort
            let newFetchOptions = fp.set('page.number', 0, fetchOptions);
            if (sort && field === sort.field) {
                if (sort.direction === 'asc') {
                    newFetchOptions = fp.set(
                        'sort.direction',
                        'desc',
                        newFetchOptions,
                    );
                } else {
                    newFetchOptions = fp.set('sort', null, fetchOptions);
                }
            } else {
                newFetchOptions = fp.set(
                    'sort',
                    {field, direction: 'asc'},
                    fetchOptions,
                );
            }
            setFetchOptions(newFetchOptions);
        },
        [fetchOptions, setFetchOptions],
    );

    const setFilter = useCallback(
        (field, value) => {
            // Go to page 1 when changing filter
            let newFetchOptions = fp.set('page.number', 0, fetchOptions);

            let isEmpty = false;
            if (value === '' || value === null) {
                isEmpty = true;
            } else if (fp.isArray(value) && fp.isEmpty(value)) {
                isEmpty = true;
            } else if (fp.isObject(value) && fp.isEmpty(value)) {
                isEmpty = true;
            } else if (value === undefined) {
                isEmpty = true;
            }

            if (isEmpty) {
                newFetchOptions = fp.unset(['filter', field], newFetchOptions);
            } else {
                newFetchOptions = fp.set(
                    ['filter', field],
                    value,
                    newFetchOptions,
                );
            }
            setFetchOptions(newFetchOptions);
        },
        [fetchOptions, setFetchOptions],
    );

    return {
        fetchOptions,
        setFetchOptions,
        setSearch,
        setPage,
        setPageSize,
        setSort,
        setFilter,
    };
}

export function useDataSource({data, fetchOptions}) {
    const [state, setState] = useState('init');
    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);

    const fetch = useCallback(async () => {
        setState('busy');
        setItems([]);
        try {
            const resp = await data(fetchOptions);
            let items = fp.map(
                item => ({
                    id: item.id,
                    data: item,
                    state: {selected: false, detail: null},
                }),
                resp.rows,
            );
            setItems(items);
            setState('ready');
            setCount(resp.count);
        } catch (error) {
            console.error(error);
            setState('error');
            setError(error);
        }
    }, [data, fetchOptions, setState, setError, setItems, setCount]);

    const selectItem = useCallback(
        (item, selected) => {
            const newItems = fp.map(
                it =>
                    it.id === item.id
                        ? fp.set('state.selected', selected, it)
                        : it,
                items,
            );
            setItems(newItems);
        },
        [items, setItems],
    );

    const selectAllItems = useCallback(
        selected => {
            const newItems = fp.map(fp.set('state.selected', selected), items);
            setItems(newItems);
        },
        [items, setItems],
    );

    const selectedItems = useMemo(
        () => fp.filter(it => it.state.selected, items),
        [items],
    );

    const someItemsSelected = useMemo(() => selectedItems.length > 0, [
        selectedItems,
    ]);

    const allItemsSelected = useMemo(
        () => selectedItems.length && selectedItems.length === items.length,
        [selectedItems],
    );

    const toggleItemDetail = useCallback(
        (item, detail) => {
            const newItems = fp.map(it => {
                if (it.id !== item.id) return it;
                if (it.state.detail === detail) {
                    return fp.set('state.detail', null, it);
                } else {
                    return fp.set('state.detail', detail, it);
                }
            }, items);
            setItems(newItems);
        },
        [items, setItems],
    );

    useEffect(() => {
        if (fetch && state !== 'busy' && state !== 'init') {
            fetch();
        }
    }, [fetchOptions]);

    return {
        fetch,
        fetchOptions,
        selectedItems,
        selectItem,
        selectAllItems,
        someItemsSelected,
        allItemsSelected,
        toggleItemDetail,
        state,
        items,
        error,
        count,
    };
}

// Controller which always uses data from the redux store for items
export function useReduxDataSource(props) {
    const result = useDataSource(props);
    const selectItems = useMemo(() => selectItemsFromState(result.items), [
        result.items,
    ]);
    const items = useSelector(selectItems);
    return {...result, items};
}

const selectItemsFromState = items => state => {
    return fp.map(it => {
        const data = jsonapi.selectObject([it.data.type, it.data.id])(state);
        return {...it, data};
    }, items);
};

function locationFetchOptions(params, defaultFetchOptions = {}, prefix = '') {
    let result = fp.cloneDeep(defaultFetchOptions);
    if (!prefix) return result;
    const rFilter = /filter\[(.*)\]/;
    const rPage = /page\[(number|size)\]/;
    for (let [key, val] of params) {
        if (key.substring(0, prefix.length) !== prefix) continue;
        key = key.substring(prefix.length);
        const filter = key.match(rFilter);
        const page = key.match(rPage);
        if (filter) {
            result = fp.set(['filter', filter[1]], JSON.parse(val), result);
        } else if (page) {
            result = fp.set(['page', page[1]], JSON.parse(val), result);
        } else if (key === 'sort') {
            if (val === '') {
                delete result.sort;
            } else if (val[0] === '-') {
                result.sort = {field: val.substring(1), direction: 'desc'};
            } else {
                result.sort = {field: val, direction: 'asc'};
            }
        } else if (key === 'q') {
            result.search = val;
        }
    }
    return result;
}

function updateLocationFetchOptions(fetchOptions, prefix = '') {
    let params = new URLSearchParams(window.location.search);
    // Clear any filters/page options
    const rFilter = /filter\[(.*)\]/;
    const rPage = /page\[(number|size)\]/;
    let keysToDelete = [];
    for (const key of params.keys()) {
        if (key.substring(0, prefix.length) !== prefix) continue;
        const k = key.substring(prefix.length);
        if (k.match(rFilter) || k.match(rPage) || k === 'sort' || k === 'q') {
            keysToDelete.push(key);
        } else {
            debugger;
        }
    }
    fp.forEach(key => params.delete(key), keysToDelete);
    // Add fetchOptions to params
    fp.pipe([
        fp.get('filter'),
        fp.toPairs,
        fp.forEach(([fkey, fval]) =>
            params.set(`${prefix}filter[${fkey}]`, JSON.stringify(fval)),
        ),
    ])(fetchOptions);
    fp.pipe([
        fp.get('page'),
        fp.toPairs,
        fp.forEach(([fkey, fval]) =>
            params.set(`${prefix}page[${fkey}]`, JSON.stringify(fval)),
        ),
    ])(fetchOptions);
    if (fetchOptions.search) params.set(`${prefix}q`, fetchOptions.search);
    if (fetchOptions.sort) {
        const {field, direction} = fetchOptions.sort;
        if (direction === 'asc') {
            params.set(`${prefix}sort`, field);
        } else if (direction === 'desc') {
            params.set(`${prefix}sort`, '-' + field);
        } else {
            console.log(
                'fetchOptions.sort is msising a direction:',
                fetchOptions.sort,
            );
            params.set(`${prefix}sort`, field);
        }
    } else {
        params.set(`${prefix}sort`, '');
    }
    return params;
}
