import React, {useMemo, useState, useEffect} from 'react';
import {Route, useLocation, useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import bcrypt from 'bcryptjs-react';
import UriTemplate from 'uritemplate';

import {useSelector, useDispatch} from 'react-redux';

import {
    useStorage,
    debounce,
    generateRandomString,
    pkceChallengeFromVerifier,
    useMemoDebugger,
} from '../util';
import {useAsyncError} from '../components/Error';
import {auth, jsonapi} from '../ducks';

const attrName = col => col.field.split('.').slice(2).join('.');
const fieldName = field => field.split('.').slice(1).join('.');

const defaultFilterFormatter = (field, value) => {
    const fname = fieldName(field);
    const fval = fp.isArray(value)
        ? {$in: value}
        : fp.isObject(value)
        ? value
        : fp.isString(value)
        ? '^' + value
        : value;
    return [fname, JSON.stringify(fval)];
};

const defaultSortFormatter = (field, direction) => {
    const fname = fieldName(field);
    if (direction === 'desc') {
        return '-' + fname;
    } else {
        return fname;
    }
};

var _pendingFetchConstants = null;
const fetchConstants = async (api, constantsHref) => {
    if (_pendingFetchConstants) {
        return await _pendingFetchConstants;
    }
    const promise = api.fetchJson(constantsHref);
    _pendingFetchConstants = promise;
    return await promise;
};

class ApiError extends Error {
    constructor(message, response) {
        super(message);
        this.response = response;
        this._json = null;
        this._text = null;
    }

    json = async () => {
        await this._resolveResponse();
        return this._json;
    };

    text = async () => {
        await this._resolveResponse();
        return this._text;
    };

    _resolveResponse = debounce(async () => {
        if (this.response.bodyUsed) {
            return;
        }
        try {
            this._text = await this.response.text();
        } catch (e) {
            console.warn('Error reading text', e, this.response);
            return;
        }
        try {
            this._json = JSON.parse(this._text);
        } catch (e) {
            console.warn('Error parsing JSON', e, this.response);
        }
    });
}

class Api {
    constructor({
        ui_home,
        api_root,
        dispatch,
        history,
        directory,
        logoutUrls,
        setLogoutUrls,
        accessToken,
        refreshToken,
        setRefreshToken,
        pkceCodeVerifier,
        pkceCodeChallenge,
        ui_login,
        client_id,
        set_client_id,
        onError,
    }) {
        this.ui_home = ui_home;
        this.ui_login = ui_login;
        this.api_root = api_root;
        this.client_id = client_id;
        this.set_client_id = set_client_id;
        this._dispatch = dispatch;
        this._history = history;
        this._directory = directory;
        this._logoutUrls = logoutUrls;
        this._setLogoutUrls = setLogoutUrls;
        this._accessToken = accessToken;
        this._refreshToken = refreshToken;
        this._setRefreshToken = setRefreshToken;
        this._pkceCodeVerifier = pkceCodeVerifier;
        this._pkceCodeChallenge = pkceCodeChallenge;
        this.onError = onError;
    }

    url_for = (name, options = {}) => {
        if (!this._directory) {
            throw new Error('Try to get url before directory is initialized');
        }
        let template = this._directory.data.links[name];
        if (!template) {
            throw new Error('Invalid directory name ' + name);
        }
        return UriTemplate.parse(template).expand(options);
    };

    get directory() {
        console.warn('Accessing directory directly');
        debugger;
        return this._directory;
    }

    get hasDirectory() {
        return !!this._directory;
    }

    bootstrap = debounce(async () => {
        if (!this._directory) {
            const resp = await this.fetch(this.api_root);
            const data = await resp.json();
            this._dispatch(jsonapi.directory(data));
            this._directory = data;
        }
        const constantsHref = this._directory && this.url_for('constants.root');
        if (!this.client_id && constantsHref) {
            const constants = await fetchConstants(this, constantsHref);
            const client_id = fp.get('client_id', constants);
            if (client_id) {
                this.client_id = client_id;
                this.set_client_id(client_id);
            }
        }
        if (!fp.isEmpty(this._logoutUrls)) {
            const [first, ...rest] = this._logoutUrls;
            this._setLogoutUrls(rest);
            window.location = first;
        }
        if (!this.isAuthorized() && this._refreshToken) {
            await this.login({
                grant_type: 'refresh_token',
                client_id: this.client_id,
                refresh_token: this._refreshToken,
            });
        }
    });

    isAuthorized = () => {
        return !!this._accessToken;
    };

    hasRefreshToken = () => {
        return !!this._refreshToken;
    };

    fetch = async (url, options = {}) => {
        const {json, headers = {}, ...o} = options;
        if (json) {
            o.body = JSON.stringify(json);
            headers['Content-Type'] = 'application/json';
        }
        if (this._accessToken) {
            await this._refreshIfNeeded();
            headers.Authorization = `Bearer ${this._accessToken.data}`;
        }
        o.headers = headers;
        o.credentials = 'include';
        const resp = await fetch(url, o);
        if (!resp.ok) {
            const err = new ApiError(`Bad response`, resp);
            this.onError(err);
            throw err;
        }
        return resp;
    };

    login = async args => {
        var data;
        try {
            // Don't use this.fetch b/c it tries to refresh the token
            let resp = await fetch(
                this.url_for('oauth.token'),
                // 'http://localhost:5005/token',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(args),
                    credentials: 'include',
                },
            );
            if (!resp.ok) {
                const err = new ApiError(`Bad response to login`, resp);
                this.onError(err);
                throw err;
            }

            data = await resp.json();
            this._processTokenData(data);
            resp = await this.fetch(this.url_for('oauth.userinfo'));
            data = await resp.json();
            this._dispatch(auth.userinfo(data));
        } catch (e) {
            console.error('Error logging in', args);
            this._setRefreshToken(null);
            this._accessToken = null;
            this._dispatch(auth.logout());
            this._dispatch(jsonapi.clearData());
            this.onError(e);
            throw e;
        }
        return data;
    };

    loginSrp = async (username, password) => {
        const resp = await fetch(this.url_for('oauth.token'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                grant_type: 'password',
                client_id: this.client_id,
                username,
                code_challenge: this._pkceCodeChallenge,
                code_challenge_method: 'S256',
            }),
            credentials: 'include',
        });
        // 403 is expected here
        const data = await resp.json();
        if (data.alg !== 'bcrypt') {
            const err = new ApiError(`Unknown SRP alg ${data.alg}`);
            this.onError(err);
            throw err;
        }
        const password_hash = bcrypt.hashSync(password, data.salt);
        const password_verifier = await pkceChallengeFromVerifier(
            password_hash + this._pkceCodeVerifier + data.code,
        );
        return await this.login({
            grant_type: 'srp_authorization_code',
            client_id: this.client_id,
            code: data.code,
            code_verifier: this._pkceCodeVerifier,
            password_verifier,
        });
    };

    logout = async revoke_all => {
        const home = new URL(this.ui_home, window.location);
        if (!this._accessToken) {
            return;
        }
        const json = revoke_all
            ? {
                  revoke_all: true,
                  redirect_uri: home,
              }
            : {
                  redirect_uri: home,
                  tokens: [this._accessToken.data, this._refreshToken],
              };
        const data = await this.fetchJson(this.url_for('oauth.revoke'), {
            method: 'POST',
            json,
        });
        console.log('logout got data', data);
        this._dispatch(auth.logout());
        this._dispatch(jsonapi.clearData());
        this._setRefreshToken(null);
        this._accessToken = null;
        if (!fp.isEmpty(data.urls)) {
            const [first, ...rest] = data.urls;
            this._setLogoutUrls(rest);
            window.location = first;
        } else {
            console.log('pushing home', {
                push: this._history.push,
                home: this.ui_home,
            });
            this._history.push(this.ui_home);
        }
    };

    isAuthorized = () => !!this._accessToken;

    fetchJson = async (url, options = {}) => {
        const resp = await this.fetch(url, options);
        if (!resp.ok) {
            const e = new ApiError('Fetch error', resp);
            this.onError(e);
            throw e;
        }
        if (resp.status === 204) {
            return null;
        } else {
            const json = await resp.json();
            this._dispatch(jsonapi.receiveData(json));
            return json;
        }
    };

    fetchJsonApi = (url, options = {}) => {
        const {
            fields = {},
            filter = {},
            page = {},
            sort = [],
            include = [],
            ...rest
        } = options;
        url = new URL(url);
        fp.pipe([
            fp.toPairs,
            fp.forEach(([type, fieldNames]) =>
                url.searchParams.set(`fields[${type}]`, fieldNames.join(',')),
            ),
        ])(fields);
        fp.pipe([
            fp.toPairs,
            fp.forEach(([k, v]) => url.searchParams.set(`filter[${k}]`, v)),
        ])(filter);
        fp.pipe([
            fp.toPairs,
            fp.forEach(([k, v]) => url.searchParams.set(`page[${k}]`, v)),
        ])(page);
        if (sort.length > 0) {
            url.searchParams.set('sort', sort.join(','));
        }
        if (include.length > 0) {
            url.searchParams.set('include', include.join(','));
        }
        return this.fetchJson(url, rest);
    };

    fetchAllJsonApi = async (url, options = {}) => {
        let allData = [];
        const pageLimit = fp.getOr(20, 'page.limit', options);
        options = fp.omit('page', options);
        options = fp.set('page.limit', pageLimit, options);
        while (url) {
            const resp = await this.fetchJsonApi(url, options);
            allData = [...allData, ...resp.data];
            url = fp.get('links.next', resp);
        }
        return allData;
    };

    fetchMaterialTable = async (url, options) => {
        while (true) {
            const resp = await this._fetchMaterialTable(url, options);
            if (!fp.isEmpty(resp.data) || options.page <= 0) {
                return resp;
            }
            options.page--;
        }
    };

    jsonApiFromDataTableOptions = (fetchOptions, options = {}) => {
        const filter = fp.pipe([
            fp.toPairs,
            fp.map(([field, value]) => {
                const filterFormatter = fp.getOr(
                    defaultFilterFormatter,
                    ['filterFormatter', field],
                    options,
                );
                return filterFormatter(field, value);
            }),
            fp.fromPairs,
        ])(fetchOptions.filter);
        if (!fp.isEmpty(fetchOptions.search)) filter._q = fetchOptions.search;
        const opts = {
            filter,
            page: {
                limit: fetchOptions.page.size,
                offset: fetchOptions.page.size * fetchOptions.page.number,
            },
        };
        if (fetchOptions.sort) {
            let {field, direction} = fetchOptions.sort;

            const sortFormatter = fp.getOr(
                defaultSortFormatter,
                ['sortFormatter', field],
                options,
            );
            opts.sort = [sortFormatter(field, direction)];
        }
        if (fetchOptions.include) {
            opts.include = fetchOptions.include;
        }
        return fp.merge(options, opts);
    };

    fetchDataTable = async (url, fetchOptions, options = {}) => {
        const data = await this.fetchJsonApi(
            url,
            this.jsonApiFromDataTableOptions(fetchOptions, options),
        );
        return {
            rows: data.data,
            count: fp.getOr(data.data.length, 'meta.total', data),
        };
    };

    _fetchMaterialTable = async (url, options) => {
        const {
            filters,
            orderBy,
            orderDirection,
            page,
            pageSize,
            search,
            totalCount,
            ...rest
        } = options;
        const jsonApiFilters = fp.pipe([
            fp.map(flt => {
                const k = attrName(flt.column);
                if (fp.isArray(flt.value)) {
                    if (fp.isEmpty(flt.value)) {
                        return null;
                    } else {
                        return [k, JSON.stringify({$in: flt.value})];
                    }
                }
                if (flt.operator === '=') {
                    return [k, '^' + flt.value];
                } else {
                    return null;
                }
            }),
            fp.filter(x => x !== null),
            fp.fromPairs,
        ])(filters);
        if (search) {
            jsonApiFilters._q = search;
        }
        const jsonApiSort = [];
        if (orderDirection === 'desc') {
            jsonApiSort.push('-' + attrName(orderBy));
        } else if (orderDirection === 'asc') {
            jsonApiSort.push(attrName(orderBy));
        }

        const data = await this.fetchJsonApi(url, {
            filter: jsonApiFilters,
            page: {
                limit: pageSize,
                offset: page * pageSize,
            },
            sort: jsonApiSort,
            ...rest,
        });
        return {
            data: fp.map(row => ({data: row}), data.data), // prevent mutation of state
            totalCount: data.meta.total,
            page,
        };
    };

    authorizeLink = options => {
        let {intent, redirect_uri, state, provider_id} = options;
        const uri = new URL(this.url_for('oauth.authorize'));
        redirect_uri = new URL(redirect_uri, window.location).href;
        uri.searchParams.set('client_id', this.client_id);
        uri.searchParams.set('code_challenge', this._pkceCodeChallenge);
        uri.searchParams.set('code_challenge_method', 'S256');
        uri.searchParams.set('redirect_uri', redirect_uri);
        uri.searchParams.set('intent', intent);
        if (state) {
            if (fp.isObject(state)) {
                uri.searchParams.set('state', JSON.stringify(state));
            } else {
                uri.searchParams.set('state', state);
            }
        }
        if (provider_id) {
            uri.searchParams.set('provider_id', provider_id);
        }
        return uri.toString();
    };

    codeLogin = async code => {
        const options = {
            grant_type: 'authorization_code',
            code,
            client_id: this.client_id,
            code_verifier: this._pkceCodeVerifier,
        };
        return await this.login(options);
    };

    codeLink = async code => {
        await this.fetchJson(this.url_for('oauth.link'), {
            method: 'POST',
            json: {
                grant_type: 'authorization_code',
                code,
                client_id: this.client_id,
                code_verifier: this._pkceCodeVerifier,
            },
        });
    };

    _refreshIfNeeded = async () => {
        if (fp.isEmpty(this._accessToken)) return;
        const {exp} = this._accessToken;
        const now = new Date().getTime();
        if (exp - now > 5000) return;
        await this.tryRefresh();
    };

    tryRefresh = debounce(async () => {
        if (this._refreshToken) {
            await this.login({
                grant_type: 'refresh_token',
                client_id: this.client_id,
                refresh_token: this._refreshToken,
            });
            return true;
        }
        return false;
    });

    _processTokenData = data => {
        const now = new Date();
        const exp = now.getTime() + 1000 * data.expires_in;
        this._accessToken = {
            data: data.access_token,
            exp,
        };
        this._dispatch(auth.login(this._accessToken));
        if (data.refresh_token) {
            this._setRefreshToken(data.refresh_token);
        }
    };
}

const Context = React.createContext();

export const useApi = () => React.useContext(Context);

export const ApiRoute = ({authorized, ...props}) => {
    const api = useApi();
    const history = useHistory();
    const location = useLocation();
    const [init, setInit] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const boot = async () => {
            await api.bootstrap();
            if (authorized && !api.isAuthorized()) {
                history.push(api.ui_login || api.ui_home, {next: location});
            }
            setReady(true);
        };
        if (!init) {
            setInit(true);
            boot();
        }
    }, [init, api, authorized, history, location]);
    return ready ? <Route {...props} /> : null;
};

export default function Provider({
    children,
    onError,
    client_id,
    ui_home,
    ui_login,
    api_root,
    history,
    ...props
}) {
    const dispatch = useDispatch();
    const directory = useSelector(jsonapi.selectDirectory);
    const accessToken = useSelector(auth.selectAccessToken);
    const [logoutUrls, setLogoutUrls] = useStorage(
        sessionStorage,
        'aauth.logout_urls',
        [],
    );
    const [refreshToken, setRefreshToken] = useStorage(
        sessionStorage,
        'aauth.refresh_token',
    );
    const [pkceCodeVerifier, setPkceCodeVerifier] = useStorage(
        sessionStorage,
        'aauth.pkce_code_verifier',
    );
    const [pkceCodeChallenge, setPkceCodeChallenge] = useState();
    const [_client_id, _set_client_id] = useState(client_id);

    useEffect(() => {
        if (!pkceCodeVerifier) {
            setPkceCodeVerifier(generateRandomString(33));
        }
    }, [pkceCodeVerifier, setPkceCodeVerifier, setPkceCodeChallenge]);

    useEffect(() => {
        pkceChallengeFromVerifier(pkceCodeVerifier).then(setPkceCodeChallenge);
    }, [pkceCodeVerifier, setPkceCodeChallenge]);

    const names = (
        'dispatch directory accessToken refreshToken setRefreshToken' +
        ' logoutUrls setLogoutUrls pkceCodeVerifier pkceCodeChallenge onError' +
        ' _client_id _set_client_id ui_home ui_login api_root history'
    ).split(' ');

    const api = useMemoDebugger(
        () =>
            new Api({
                dispatch,
                directory,
                accessToken,
                refreshToken,
                setRefreshToken,
                logoutUrls,
                setLogoutUrls,
                pkceCodeVerifier,
                pkceCodeChallenge,
                onError,
                client_id: _client_id,
                set_client_id: _set_client_id,
                ui_home,
                ui_login,
                api_root,
                history,
            }),
        [
            dispatch,
            directory,
            accessToken,
            refreshToken,
            setRefreshToken,
            logoutUrls,
            setLogoutUrls,
            pkceCodeVerifier,
            pkceCodeChallenge,
            onError,
            _client_id,
            _set_client_id,
            ui_home,
            ui_login,
            api_root,
            history,
        ],
        names,
    );

    return <Context.Provider value={api}>{children}</Context.Provider>;
}
