import React from 'react';

import {useApi} from '@arborian/narrf';
import {useStorage} from '_app/lib.js';

const Context = React.createContext();

export const useClientId = () => React.useContext(Context);

export function ClientIdProvider({children}) {
    const api = useApi();
    const [clientId, setClientId] = useStorage(
        sessionStorage,
        'aauth.client_id',
        'aauth',
    );
    React.useEffect(() => {
        if (api.client_id !== clientId) {
            api.set_client_id(clientId);
        }
    }, [api, clientId]);

    const value = [clientId, setClientId];

    return <Context.Provider value={value}>{children}</Context.Provider>;
}
